import { useContext, useEffect } from 'react';
import { EntityContext } from '@lmig/dotcom-aspect-comparion-components/EntityContext';
import PropTypes from 'prop-types';
import capSpec from '@lmig/dotcom-aspect-comparion-helpers/specs/cap.spec';
import agentSpec from '@lmig/dotcom-aspect-comparion-helpers/specs/agent.spec';

const Entity = ({ agent = {}, cap = {}, clearSession = false }) => {
  const { dispatchEntities } = useContext(EntityContext);

  useEffect(() => {
    if (clearSession) {
      dispatchEntities({ action: "CLEAR" });
    }
  }, [dispatchEntities, clearSession]);

  useEffect(() => {
    const dispatchObject = {
      agent: {},
      cap: {},
    };

    if (agent.nNumber) {
      dispatchObject.agent = agent;

      if (window?.utag_data) {
        window.utag_data.nNumber = agent.nNumber;
      }

      if (window?.galileoData) {
        window.galileoData.nNumber = agent.nNumber;
      }
    }

    if (cap.partnerId) {
      dispatchObject.cap = cap;

      if (window?.utag_data) {
        window.utag_data.partnerId = cap.partnerId;
      }

      if (window?.galileoData) {
        window.galileoData.partnerId = cap.partnerId;
      }
    }

    dispatchEntities(dispatchObject);
  }, []);
};

Entity.propTypes = {
  cap: PropTypes.shape(capSpec),
  agent: PropTypes.shape(agentSpec),
  clearSession: PropTypes.boolean,
};

export default Entity;